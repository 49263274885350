.privacy-policy{
    padding: 5rem;

    h2{
        text-align: center;
    }

    ol{
        margin-top: 0.5rem;
    }

    span{
        display: grid;
        place-items: center;

    .privacy-button{
        padding: 0.625rem 3.8rem;
          border-radius: 0.5rem;
          font-weight: 700;
          border: none;
          background-color: #1b7fff;
          font-size: 1.2rem;
          cursor: pointer;
    }}
}


@media (min-width: 100px) and (max-width: 560px) {
    .privacy-policy{
        padding: 3rem;
    }
}


