.about-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 50px 20px;
  margin-top: 70px;
  .about-header {
    text-align: center;
    margin-bottom: 50px;
    h1 {
      font-size: 2.5rem;
    }
  }
  .about-content {
    line-height: 1.6;
    font-size: 1.2rem;
    color: black;
    background-color: white;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    padding: 20px;
    button {
      .signup_btn {
        text-decoration: none;
        color: white;
      }
    }
  }
}

.container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  padding: 20px;
  .title {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
  }
  .content {
    font-size: 1.2rem;
    line-height: 1.5;
    color: black;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    padding: 20px;
  }
}
