.logo {
  display: flex;
  color: white;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  .logo-image {
    width: 6rem;
  }

  .logo-title {
    margin-left: -1rem;
    font-family: "Segoe UI", system-ui, "Apple Color Emoji", "Segoe UI Emoji", sans-serif;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 400;
  }
}


@media (min-width: 100px) and (max-width: 560px) {

  .logo {
    .logo-image {
      width: 5rem;
      transform: scale(0.7);
    }

    .logo-title {
      font-size: 1rem;
      margin-left: -1.1rem;
    }
  }
}

@media screen and (max-width: 560px) {
  .logo {

    .logo-image {
      width: 4rem;
    }

    .logo-title {
      font-size: 1rem;
    }
  }
}