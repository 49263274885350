.coverflow-container {
    position: relative;
    width: 100%;
    // margin: -2rem auto 0 auto;
    padding: 0 0 5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #05051e;
    margin-top: -1px;
}

.nav-button {
    border: none;
    font-size: 3rem;
    font-weight: 600;
    background: #315f7a70;
    color: #4569d4;
    cursor: pointer;
    position: absolute;
    z-index: 10;
    width: 4rem;
    height: 4.4rem;
    border: 1px solid #5c5a5a;
    border-radius: 1rem;

    &.left {
        left: 10rem;
    }

    &.right {
        right: 10rem
    }
}

.coverflow-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    perspective: 1000px;
    width: 100%;
    height: 300px;
    // overflow: hidden;
}

.coverflow-card {
    position: absolute;
    width: 400px;
    height: 100%;
    background-color: #334155;
    background-color: #556a8a8a;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 0 14px #e4d9d9;
    transition: transform 0.5s ease, opacity 0.5s ease;
    transform-origin: center;

    z-index: 0;
    opacity: 0;

    h2 {
        font-size: 5.2rem;
        margin-bottom: 10px;

        background: linear-gradient(135deg, #955595, #399580);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }

    p {
        font-size: 1rem;
        font-weight: 800;
        margin: 0 15px;
        line-height: 1.5;
        color: #A3A8BF;
    }

    &.activeSlide {
        transform: translateX(0) scale(1);
        opacity: 1;
        z-index: 5;
    }

    &.lastSlide {
        transform: translateX(-100%) scale(0.8) rotateY(45deg);
        // opacity: 0.66;
        opacity: 1;
        z-index: 1;
        width: 370px;
        box-shadow: 0 8px 27px #ffffff66;
    }

    &.nextSlide {
        transform: translateX(100%) scale(0.8) rotateY(-45deg);
        opacity: 0.66;
        // opacity: 1;
        z-index: 1;
        width: 370px;
        box-shadow: 0 8px 27px rgb(71 66 66 / 48%);
    }
}

// Media Queries for Responsiveness
@media (max-width: 1024px) {
    .nav-button {
        font-size: 2.5rem;
        width: 3rem;
        height: 3rem;

        &.left {
            left: 1rem;
        }

        &.right {
            right: 1rem;
        }
    }

    .coverflow-card {
        h2 {
            font-size: 3rem;
        }

        p {
            font-size: 0.9rem;
        }
    }
}

@media (max-width: 768px) {
    .coverflow-wrapper {
        height: 250px;
    }

    .coverflow-container {
        padding: 0 0 2rem 0;
    }

    .coverflow-card {
        width: 65%;
        padding: 0 1rem;

        h2 {
            font-size: 2.5rem;
        }

        p {
            font-size: 0.8rem;
        }
    }

    .nav-button {
        font-size: 2rem;
        width: 2.5rem;
        height: 2.5rem;
    }
}

@media (max-width: 480px) {
    .coverflow-wrapper {
        height: 200px;
    }

    .coverflow-card {
        width: 78%;

        h2 {
            font-size: 4rem;
        }

        p {
            font-size: 0.88rem;
            margin: 0;
        }
    }

    .coverflow-container {
        padding: 0 0 4rem 0;
    }

    .nav-button {
        font-size: 1.5rem;
        width: 2rem;
        height: 2rem;
    }
}