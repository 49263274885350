.footer-container {
  padding: 2rem;
  text-align: center;
  background-color: #05051e;
  color: rgb(223, 219, 219);

  .footer-logo {
    display: grid;
    place-items: center;
    font-size: 2rem;
  }

  .footer-links-section {
    margin: 2rem;

    .footer-links {
      font-size: 1.2rem;
      margin: 0 2rem;
      color: #a3a8bf;
      text-decoration: none;
    }
  }


  .footer-lower-section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;

    .twitter-icon {
      filter: invert(1);
      border-radius: 1rem;
      background-color: white
    }

    img {
      width: 2.2rem;
      cursor: pointer;
    }
  }
}



@media only screen and (max-width: 560px) {

  .footer-container {
    padding: 1rem 1rem;

    .footer-links-section {
      margin: 0.2rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
      align-items: center;
      margin-bottom: 1rem;
    }
  }
}

@media (min-width:561px) and (max-width: 1120px) {

  .footer-container {
    padding: 1rem 2rem;

  }
}

@media screen and  (min-width: 560px) and  (max-width: 768px) {
  .footer-container .footer-links-section {
    margin: 2rem 1rem;
  }
}

@media only screen and (max-width: 560px) {

  .footer-container {
    padding: 1rem 1rem;
  }

  .footer-container .footer-links-section {
    margin: 2rem 0;
  }

  .footer-container .footer-lower-section {
    gap: 2rem;
  }

}

@media screen and (max-width: 500px) {
  .footer-container .footer-links-section .footer-links {
    margin: 0 0.4rem;
  }
}

@media screen and (max-width: 360px) {
  .footer-container .footer-links-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .footer-container .footer-links-section .footer-links {
    margin: 0;
  }
}