.loader-active {
    position: relative;
    filter: blur(0.06rem);
    opacity: 0.6;
    z-index: 999;
}

.loader-bg {
    width: 100vw;
    height: 100vh;
    background: #ffffff75;
    position: absolute;
    z-index: 998;
}

.loader-inner-container {
    filter: blur(0rem);
    opacity: 1;
    border-radius: 50%;
    border: 4px solid rgb(0, 0, 0);
    position: absolute;
    padding: 14px;
    top: 25rem;
    left: 50%;
    transform: translate(-50%, -95%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70px;
    height: 70px;
}

.loader-inner-container div {
    width: 4px;
    // height: 26px;
    background: rgb(0, 0, 0);
    animation: lds 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.loader-inner-container div:nth-child(1) {
    animation-delay: -0.24s;
}

.loader-inner-container div:nth-child(2) {
    animation-delay: -0.12s;
}

.loader-inner-container div:nth-child(3) {
    animation-delay: 0;
}


@keyframes lds {
    0% {
        top: 8px;
        height: 64px;
    }

    50%,
    100% {
        top: 24px;
        height: 32px;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-1rem);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes buttonHover {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.1);
    }
}