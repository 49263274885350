.navbar-main-Container {
  background-color: #05051e;
  border-bottom: 0.5px solid rgb(83 81 81 / 40%);
  padding: 0 2rem;
  width: 100%;
  height: 10%;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .navbar-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 3rem;
    height: 100%;

    .navbar-nav {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.8rem;
      height: 100%;

      .nav-item {
        .nav-link {
          display: flex;
          align-items: center;
          color: #a3a8bf;
          height: 100%;
          text-decoration: none;
          font-size: 1rem;
          line-height: 1.4rem;
          font-weight: 600;
        }

        .nav-link:hover {
          background: linear-gradient(135deg, #955595, #399580);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;

          font-weight: 600;
        }
      }
    }
  }


  .navbar-options {
    list-style: none;
    height: 100%;
    width: 100%;
    padding: 0rem 3rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .navbar-account-section {
      display: flex;
      gap: 0.79rem;

      .navbar-button {
        padding: 0.625rem 1rem;
        border-radius: 0.5rem;
        cursor: pointer;

        color: #a3a8bf;
        font-size: 1rem;
        line-height: 1.2rem;
        font-weight: 400;
      }

      .navbar-signup-button {
        background: linear-gradient(135deg, #955595, #399580);
      }

      .navbar-login-button {
        background-color: #0c0e13;
        border: 1px solid #b4b1b170;
      }
    }
  }

  .navbar-content-for-mobile-view {
    display: none;
  }

  // slide-in menu
  .mobile-slide-menu {
    position: fixed;
    top: 0;
    left: -100vw; 
    width: 80vw;
    height: 100vh;
    background-color: #1c1c1c;
    box-shadow: 1px 2px 5px gray;
    z-index: 1001;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    transition: left 0.4s ease-in-out;

    // transition effect for sliding menu
    &.slide-in {
      left: 0; 
    }

    &.slide-out {
      left: -100vw; 
    }

    .mobile-nav-item {
      margin: 1rem 0;
      text-align: center;

      .nav-link {
        font-size: 1.5rem;
        color: #fff;
        text-decoration: none;

        &:hover {
          color: #1b7fff;
        }
      }
    }

    .mobile-account-section {
      margin-top: 2rem;
      text-align: center;

      .mobile-signup-button {
        background-color: #955595;
        padding: 0.8rem 2rem;
        border: none;
        color: #fff;
        font-size: 1.2rem;
        cursor: pointer;
        margin-bottom: 1rem;
      }

      .mobile-login-button {
        background-color: #1b7fff;
        padding: 0.8rem 2rem;
        border: none;
        color: #fff;
        font-size: 1.2rem;
        cursor: pointer;
      }
    }
  }

}

@media screen and (max-width: 600px) {
  .navbar-main-Container {
    .navbar-content-for-mobile-view {
      display: block;
      height: auto;
      width: auto;
      margin-right: 1.6rem;

      div {
        background-color: #fff;
        border-radius: 1rem;
        height: .2rem;
        margin: .3rem;
        transition: .5s ease-in-out;
        width: 2rem;
      }
    }
  }

}

@media (min-width: 100px) and (max-width: 560px) {

  .navbar-main-Container {
    padding: 0.2rem;

    font-size: 0.8rem;
    height: 10%;

    .navbar-content {
      justify-content: space-between;
      position: relative;

      .navbar-logo {
        transform: scale(1.8);
        margin-left: 1rem;
      }

      .navbar-nav {
        flex-direction: column;
        gap: 1rem;
        text-align: center;
        font-size: 2.5rem;
        margin-top: 2.5rem;
        height: 30%;
        display: none;

        .nav-item {
          // display: none;

          .nav-link {
            color: white;
            height: 100%;
            text-decoration: none;
          }

          .nav-link:hover {
            border-bottom: 4px solid #1b7fff;
          }
        }
      }

      .navbar-options-for-mobile-view {
        position: absolute;
        justify-content: initial;
        gap: 3rem;
        width: 100vw;
        top: 0px;
        overflow-y: hidden;
        right: 0px;
        height: 100vh;
        // background-color: #000000;
        background: #ffffff;
        flex-direction: column;
      }

      .navbar-content-for-mobile-view {
        display: flex;
        flex-direction: column;
        margin-right: 1rem;
        cursor: pointer;

        div {
          background-color: rgb(255, 255, 255);
          width: 2rem;
          border-radius: 1rem;
          margin: 0.3rem;
          height: 0.2rem;
        }
      }

      .after-click {
        div:nth-child(1) {
          position: relative;
          transform: rotate(45deg);
          top: 0.31rem;
          left: 0rem;
          transition: .5s ease-in-out;
        }

        div:nth-child(2) {
          opacity: 0;
        }

        div:nth-child(3) {
          position: relative;
          transform: rotate(-45deg);
          top: -21px;
          transition: .5s ease-in-out;
          left: 0px;
        }

      }
    }

    .navbar-options {
      display: none;

      .navbar-account-section {

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;

        .button {
          padding: 0.625rem 1.8rem;
          border-radius: 0.5rem;
          font-weight: 700;
          border: none;
          font-size: 1.2rem;
          color: #fff;
          cursor: pointer;
        }

        .navbar-signup-button {
          background-color: #1c1c1c;
        }

        .navbar-login-button {
          background-color: #1b7fff;
        }
      }
    }
  }
}