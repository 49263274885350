.header_section {
  margin-top: 6rem;
  padding: 2rem;
  font-size: 20px;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .form-container {
    background-color: #fff;
    padding: 30px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    flex-basis: 100%;
    margin-bottom: 30px;
    .h2 {
      font-size: 36px;
      margin-bottom: 30px;
      text-align: center;
    }
    form {
      display: flex;
      flex-direction: column;
      width: 100%;
      .form-group {
        margin-bottom: 20px;
        label {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 5px;
        }
        .input,
        .textarea {
          border: none;
          border-radius: 5px;
          padding: 15px;
          width: 100%;
          font-size: 16px;
          color: #333;
          background-color: #f5f5f5;
          margin-top: 5px;
          margin-bottom: 15px;
          input:focus,
          textarea:focus {
            outline: none;
            box-shadow: 0 0 3px #2196f3;
          }
        }
        textarea {
          height: 150px;
        }
      }
      .buttonSubmit {
        background-color: #2196f3;
        color: #fff;
        border: none;
        border-radius: 5px;
        padding: 15px 30px;
        cursor: pointer;
      }
    }
  }
}
