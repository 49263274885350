.slider-main-container {
  background-color: #0c0c0f;
  color: white;

  .slider-content {
    padding: 0.6rem;
    padding-bottom: 0.8rem;
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .coverflow-container {
    margin-top: -3rem;
  }
}

@media only screen and (max-width: 560px) {

  .slider-main-container {
    .slider-content {
      margin-top: 4rem;
    }
  }
}