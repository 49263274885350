@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: "Barlow", sans-serif;
  box-sizing: border-box;
}

body {
  height: 100vh;
  font-family: "Barlow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #000;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(135deg, #955595, #399580);
  border-radius: 4px;
}

/* ::-webkit-scrollbar-thumb:hover {
  background: red;
} */