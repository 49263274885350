.userFeedback-main-container {
  width: 100%;
  color: #c3c8e0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  position: relative;
  z-index: 999;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: "Barlow", sans-serif;
}